<template>
  <div class="contentBox">
    <el-image :src="bgSrc" class="pageBg" fit="cover"></el-image>
    <div class="innerBox">
      <div
        class="mainBox"
        v-loading="loading"
        :element-loading-text="tipText"
      ></div>
    </div>
  </div>
</template>

<script>
import {
  Form,
  FormItem,
  Input,
  Button,
  Radio,
  RadioGroup,
  Dialog,
} from "element-ui";
import { mapState, mapMutations } from "vuex";
import base from '../api/base';
export default {
  name: "forgetPsd",
  components: {
    [Input.name]: Input,
    [Button.name]: Button,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      bgSrc: "",
      loading: true,
      pageType: "code",
      tipText: "登录中...",
    };
  },
  created() {
    this.check();
    this.bgSrc = this.$utils.addOssurlHeader("web_site/login_bg/bg_ceping.jpg");
  },
  methods: {
    ...mapMutations(["login", "signOut"]),
    check() {
      const that = this;

      //url获取code
      let code = this.$route.query.code;
      let login = this.$route.query.login;
      let isRefreshToken = this.$route.query.isRefreshToken;
      let isLogout = this.$route.query.isLogout;
      console.log(code, "code");
      console.log(login, "login");
      //获取token和用户信息
      if (code) {
        //通过code获取登录信息
        this.pageType = "code";
        let params = { code: code, scope: "ceping_onsite" };
        this.$api
          .authLogin(params)
          .then((res) => {
            console.log(res, "res"); //登录信息
            if (res.success) {
              let result = res.body.userInfo;
              let tokenInfo = res.body.tokenInfo;
              tokenInfo.expiresTime =
                new Date().getTime() + (tokenInfo.expiresIn - 3600) * 1000;
              let loginInfo = {
                userId: result.id,
                userName: result.name,
                token: res.token,
                mobile: result.mobile,
                tokenInfo: tokenInfo,
              };
              let assessmentList = res.body.assessmentList;
              that.$storage.setStorage('assessmentList',assessmentList);
              that.login(loginInfo);
               window.location.replace(base.baseUrl);
            } else {
              this.$alert(res.msg, {
                confirmButtonText: "确定",
                callback: (action) => {
                  if (res.token) {
                      let userInfo = {
                          token:res.token
                      }
                       localStorage.setItem('userInfo',JSON.stringify(userInfo));
                    that.$signOut.signOut();
                  } else {
                    that.$checkLogin.checkLogin();
                  }
                },
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (isRefreshToken == 1) {
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        let refreshToken = userInfo.tokenInfo.refreshToken;
        const that = this;
        let params = {
          refreshToken: refreshToken,
        };
        that.$api
          .refreshToken(params)
          .then((res) => {
            if (res.success) {
              console.log(res);
              let userInfo = JSON.parse(localStorage.getItem("userInfo"));
              let tokenInfo = res.body.tokenInfo;
              tokenInfo.expiresTime =
                new Date().getTime() + (tokenInfo.expiresIn - 3600) * 1000;
              userInfo.token = tokenInfo.accessToken;
              userInfo.tokenInfo = tokenInfo;
              that.login(userInfo);
              window.location.replace(base.baseUrl);
            } else {
              that.signOut();
              that.$checkLogin.checkLogin("login",1);
            }
          })
          .catch((error) => {
            console.log(error);
            that.signOut();
            that.$checkLogin.checkLogin("login", 1);
          });
      } else if (isLogout == 1) {
        this.tipText = "账号已退出";
        this.pageType = "isLogout";
        console.log("退出账号");
        this.signOut();
         window.location.replace(base.baseUrl);
      } else {
        window.location.replace(base.baseUrl);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contentBox {
  min-height: 100vh;
  position: relative;
}
.pageBg {
  width: 100%;
  height: 100%;
  position: absolute !important;
  left: 0;
  top: 0;
}

.innerBox {
  overflow: hidden;
  position: relative;
  z-index: 2;
  margin-left: 40%;
}
.mainBox {
  width: 400px;
  height: 382px;
  padding: 40px;
  margin: 21vh auto 5vh auto;
  background: #ffffff;
  box-shadow: 6px 11px 46px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  overflow: hidden;
}
</style>
